import React from 'react'
import '../stylesheets/Bio.css'

const Bio = () => {
  return (
    <div className='bio'>
      <h2>Acerca de nosotros</h2>
      <p>Cerrajero a domicilio con más de 7 años de experiencia. Atendemos todas las comunas de santiago de Chile. Apertura de departamento, apertura de autos, cambio de cilindros cambio de combinaciones en puerta de madera o fierro e instalación de chapas y cerrojos de seguridad.</p>
    </div>
  )
}

export default Bio