import React from "react";
import "../stylesheets/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-upper">
        <div>
          <p style={{ textAlign: "center" }}>Mapa del sitio</p>
          <div>
            <ul className="footer-map">
              <a href="#header">
                <li>Inicio</li>
              </a>
              <a href="#services">
                <li>Servicios</li>
              </a>
              <a href="#reviews">
                <li>Reseñas</li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
