import React from 'react'
import '../stylesheets/Header.css'
import wsLogo from '../images/whatsapp-logo2.png'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import phoneLogo from '../images/phone-logo.png'
import { phoneNumber } from './Constants';

const Header = ({bottom}) => {
  return (
    <div className={bottom ? 'header bottom' : 'header'} id='header'>
      <div className={bottom ? 'header-items bottom' : 'header-items'}>
        <div>
          <span>
            <a href={`https://wa.me/${phoneNumber}?text=Hola,%20me%20interesa%20contratar%20sus%20servicios`}>
              <img className='header-logo' src={wsLogo} alt='Logo de Whatsapp'></img>
            </a>
            <a href={phoneNumber}>
              <img className='header-logo' src={phoneLogo} alt='Logo de teléfono'></img>
            </a>
          </span>
          <a href="mailto:info@correo.cl">
            <IoMdMail className='header-logo' />
          </a>
        </div>
        <div>
          <a href= "#">
            <FaFacebookSquare className='header-logo' />
          </a>
          <a href="#">
            <FaInstagramSquare className='header-logo' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header