import React, { useState, useEffect } from "react";
import "../stylesheets/SlideShow.css";
import image1 from "../images/jr.jpeg";
import image2 from "../images/jcf.jpeg";
import image3 from "../images/wc.jpeg";
import image4 from "../images/vg.jpeg";
import image5 from "../images/ha.jpeg";
import googleLogo from "../images/google-maps-logo.png";

const SlideShow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [
    {
      name: "Juan Rodriguez",
      image: image1,
      review:
        "Excelente servicio. Desde la primera conversación, Alex te entrega detalles de los trabajos a realizar, es transparente con los precios y te aconseja de acuerdo a la situación. En cuanto al trabajo, puntual en la hora acordada, prolijo y eficaz.",
      url: "https://g.co/kgs/bkVNGvb",
    },
    {
      name: "Juan Carlos Figueroa",
      image: image2,
      review:
        "Excelente trabajo. Muy claro y transparente con el servicio, el producto que utiliza y precio.",
      url: "https://g.co/kgs/NX1ukpu",
    },
    {
      name: "Wen Chung",
      image: image3,
      review:
        "Totalmente recomendable, puntual y cortés desde el principio. Su precio es totalmente entendible por visita a domicilio, saludos.",
      url: "https://g.co/kgs/xQyTMwt",
    },
    {
      name: "Valentina González",
      image: image4,
      review:
        "Alex ayer realizó un cambio de chapa, lo recomiendo al 100%, un muy buen profesional, me brindó una excelente atención!",
      url: "https://g.co/kgs/7b22VpK",
    },
    {
      name: "Héctor Alarcón",
      image: image5,
      review:
        "Eficiente servicio de Alexander. En menos de 5 minutos cambió la chapa de acceso. Totalmente recomendado ante cualquier emergencia.",
      url: "https://g.co/kgs/J9mVcej",
    },
  ];

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Nuestros clientes hablan por nosotros</h2>
      <div className="slide-show" id="reviews">
        <div className="arrow prev" onClick={prevSlide}>
        </div>
        <div className="arrow next" onClick={nextSlide}>
        </div>
        <div className="slide">
          <img
            src={images[currentSlide].image}
            alt={`Slide ${currentSlide + 1}`}
          />
        </div>
      </div>
      <div className="reviews">
        <div>
          <p>"{images[currentSlide].review}."</p>
          <p className="author"> - {images[currentSlide].name} </p>
        </div>
        <a href={images[currentSlide].url}>
          <div className="review-button">
            Ver reseña{" "}
            <img
              className="review-button-logo"
              src={googleLogo}
              alt="Logo de Google Maps"
            ></img>
          </div>
        </a>
      </div>
    </div>
  );
};

export default SlideShow;
