import "./App.css";
import Bio from "./components/Bio";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ServicesContainer from "./components/ServicesContainer";
import SlideShow from "./components/SlideShow";
import SubHeader from "./components/SubHeader";
import wslogo from "./images/whatsapp-logo2.png";
import { phoneNumber } from "./components/Constants";

function App() {
  return (
    <div className="App">
      <Header />
      <h1 style={{position: "absolute", left: "-9999px"}}>Cerrajero Alex</h1>
      <SubHeader />
      <ServicesContainer />
      <Bio />
      <SlideShow />
      <a
        href={`https://wa.me/${phoneNumber}?text=Hola,%20estoy%20interesado%20en%20sus%20servicios.`}
      >
        <img className="whatsapp-logo" src={wslogo} alt="Logo"></img>
      </a>
      <Footer />
      <Header bottom={true} />
    </div>
  );
}

export default App;
