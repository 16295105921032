import React, { useRef, useEffect, useState } from 'react';
import '../stylesheets/Service.css';

const Service = ({ serviceName, image, alt, message, last }) => {
  const serviceRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }

    // Cleanup function
    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);

  return (
    <div ref={serviceRef} className={`service ${isVisible ? 'visible' : ''}`}>
      <div>
        <img className={last ? 'last' : ''} src={image} alt={alt}></img>
      </div>
      <div>
        <a href={message}>
          <div className='contact-button'>
            <p>{serviceName}</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Service;
