import React from "react";
import "../stylesheets/SubHeader.css";
import logo from "../images/logo3.png";
import wsLogo from "../images/whatsapp-logo2.png";
import phoneLogo from "../images/phone-logo.png";
import { phoneNumber } from "./Constants";

const SubHeader = () => {
  return (
    <div className="sub-header">
      <img src={logo} alt="Logo"></img>

      <a href={`tel:${phoneNumber}`}>
        <div className="contact-button sub-header-button">
          <img
            className="button-logo"
            src={phoneLogo}
            alt="Logo de teléfono"
          ></img>{" "}
          Llamar
        </div>
      </a>
      <a
        href={`https://wa.me/${phoneNumber}?text=Hola,%20me%20interesa%20contratar%20sus%20servicios`}
      >
        <div className="contact-button sub-header-button">
          <img
            className="button-logo"
            src={wsLogo}
            alt="Logo de Whatsapp"
          ></img>{" "}
          Mensaje
        </div>
      </a>
    </div>
  );
};

export default SubHeader;
