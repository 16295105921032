import React from "react";
import "../stylesheets/ServicesContainer.css";
import Service from "./Service.jsx";
import cilindro from "../images/cilindro.webp";
import chapa from "../images/chapa.jpg";
import aperturaCasa from "../images/aperturaCasa.jpg";
import aperturaAuto from "../images/aperturaAuto.jpg";
import cambioCombinacion from '../images/cambioCombinacion.jpg';
import { phoneNumber } from './Constants';

const ServicesContainer = () => {
  return (
    <div className="services-container" id="services">
      <h2>Nuestros servicios</h2>
      <div className="services">
        <Service
          serviceName="Apertura de puerta"
          image={aperturaCasa}
          message="https://wa.me/56931286076?text=Hola,%20necesito%20abrir%20una%20casa"
          alt="Apertura de puertas"
        />
        <Service
          serviceName="Instalación de chapa y cerrojo"
          image={chapa}
          message={`https://wa.me/${phoneNumber}?text=Hola,%20necesito%20instalar%20una%20chapa%20o%20un%20cerrojo`}
          alt="Instalación de chapa y cerrojo"
        />
      </div>
      <div className="services">
        
        <Service
          serviceName="Cambio de cilindros"
          image={cilindro}
          message={`https://wa.me/${phoneNumber}?text=Hola,%20necesito%20cambiar%20un%20cilindro`}
          alt="Cambio de cilindro"
        />
        <Service
          serviceName="Apertura de autos"
          image={aperturaAuto}
          message={`https://wa.me/${phoneNumber}?text=Hola,%20necesito%20abrir%20un%20auto`}
          alt="Apertura de auto"
        />
      </div>
      <div className="services">
        <Service
          serviceName="Cambio de combinación"
          image={cambioCombinacion}
          message={`https://wa.me/${phoneNumber}?text=Hola,%20necesito%20cambiar%20la%20combinación%20de%20una%20chapa`}
          alt="Cambio de combinación de chapa"
          last = {true}
        />
      </div>
     
    </div>
  );
};

export default ServicesContainer;
